<template>
  <AuthWrap :title="$t('resetPwd.code')">
    <form class="form" @submit.prevent="resetPwd">
      <div class="phone">
        <PrimaryInput>
          <input
            type="text"
            class="text-input"
            :placeholder="phone"
            :value="phone"
            readonly
          />
        </PrimaryInput>
      </div>
      <div class="repeat">
        <PrimaryInput :errText="error ? $t(`resetPwd.${error}`) : ''">
          <input
            :placeholder="$t('resetPwd.codePlaceholder')"
            v-model="kode"
            type="text"
            class="text-input"
            @focus="error = ''"
          />
        </PrimaryInput>
        <div class="icons-repeat" @click="sendAgainCode">
          <svg
            width="2rem"
            height="2rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 20C9.76667 20 7.875 19.225 6.325 17.675C4.775 16.125 4 14.2333 4 12C4 9.76667 4.775 7.875 6.325 6.325C7.875 4.775 9.76667 4 12 4C13.15 4 14.25 4.23767 15.3 4.713C16.35 5.18767 17.25 5.86667 18 6.75V4H20V11H13V9H17.2C16.6667 8.06667 15.9377 7.33333 15.013 6.8C14.0877 6.26667 13.0833 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18C13.2833 18 14.4417 17.6333 15.475 16.9C16.5083 16.1667 17.2333 15.2 17.65 14H19.75C19.2833 15.7667 18.3333 17.2083 16.9 18.325C15.4667 19.4417 13.8333 20 12 20Z"
              fill="var(--primary-blue)"
            />
          </svg>
        </div>
      </div>
      <span class="interval primary-text"
        >{{ $t("resetPwd.codeAgain") }} {{ parts.minutes }}:
        {{ parts.seconds > 10 ? parts.seconds : "0" + parts.seconds }}</span
      >
      <PrimaryButton :title="$t('buttons.send')" />
    </form>
  </AuthWrap>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useLanguage } from "@/utils/language";
import AuthWrap from "../AuthWrap.vue";
import PrimaryInput from "../PrimaryInput.vue";
import PrimaryButton from "../PrimaryButton.vue";

export default {
  components: { AuthWrap, PrimaryInput, PrimaryButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { i18nRoute } = useLanguage();

    const phone = computed(() => store.getters["resetPassword/getPhone"]);
    const kode = ref("");
    const error = ref("");

    const resetPwd = () => {
      store.dispatch("resetPassword/phoneAuth", {
        data: {
          phone: store.getters["resetPassword/getPhone"],
          code: kode.value,
        },
        cb: (d) => {
          error.value = d.error;
          if (d.access) {
            localStorage.setItem("token", JSON.stringify(d.access));
            store.commit("auth/setAccess", d.access);

            router.push(
              i18nRoute({
                name: "AccountInfo",
              })
            );
          }
        },
        err: (e) => {
          console.log(e);
        },
      });
    };

    const backPhone = () => {
      router.push(
        i18nRoute({
          name: "ResetPassword",
        })
      );
    };

    onMounted(() => {
      if (!store.getters["resetPassword/getPhone"]) {
        backPhone();
      }
    });

    let DateCount = new Date().getTime() + 120000;

    const parts = reactive({
      minutes: "",
      seconds: "",
    });
    const difference = ref();

    function countdownTimer() {
      difference.value = new Date(DateCount) - new Date();
      if (difference.value > 0) {
        parts.minutes = Math.floor((difference.value / 1000 / 60) % 60);
        parts.seconds = Math.floor((difference.value / 1000) % 60);
      }
    }

    const sendAgainCode = () => {
      if (parts.seconds == 0) {
        DateCount = new Date().getTime() + 120000;

        store.dispatch("resetPassword/sendCode", {
          cb: () => {},
          phone: { phone: phone.value },
          err: () => {},
        });
      }
    };

    let timer;
    onMounted(() => {
      timer = setInterval(countdownTimer, 1000);
    });
    onBeforeMount(() => {
      clearInterval(timer);
    });

    return {
      phone,
      kode,
      error,
      sendAgainCode,
      backPhone,
      resetPwd,
      parts,
    };
  },
};
</script>

<style lang="scss" scoped>
.repeat {
  position: relative;
  margin-top: 1.88rem;
  .icons-repeat {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    bottom: 1.2rem;
    font-size: 1.1em;
  }
}
.interval {
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0.8;
  display: block;
}
.grid {
  font-size: 16px;

  max-width: var(--container-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(11, 1fr var(--gap)) 1fr;
  &__img {
    position: relative;
    grid-column: 1/10;
    margin-top: 5em;
    img {
      background: transparent;
      position: absolute;
      right: 0;
      top: 50%;
      width: 80%;
      transform: translateY(-50%);
    }
  }
  &__form {
    grid-column: 11/21;
    position: relative;
    margin-top: 4em;
    .form {
      font-size: 1.375em;
      .no-valid {
        font-size: 0.8em !important;
      }
      .number {
        margin-bottom: 1em;
        display: block;
      }
      .next-btn {
        font-size: 1em;
        margin-top: 2em;
      }
    }
    .title {
      font-weight: 500;
      font-size: 2em;
      margin-bottom: 1.5em;
    }
  }
}

@media screen and (max-width: 1200px) {
  .grid {
    font-size: 12px;
    display: block;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: 80%;
    &__img {
      display: none;
      margin: 0 auto;
      max-width: 575px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      position: relative;
      width: 60%;
    }
  }
}
@media screen and (max-width: 767px) {
  .grid {
    font-size: 12px;
    display: block;
    // flex-direction: column-reverse;
    margin: 0 auto;
    width: 90%;
    &__form {
      width: 100%;
      .title {
        margin-top: 2rem;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .grid {
    font-size: 12px;
    display: block;
    // flex-direction: column-reverse;
    margin: 0 auto;
    width: 90%;

    &__img {
      display: none;
      // margin: 0 auto;
      margin-left: auto;
      margin-top: 0;
      margin-right: 0;
      max-width: 200px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      margin-top: 2rem;
      position: relative;
      width: 100%;
      .title {
        margin-top: 2rem;
      }
      .input-container {
        width: 100%;
      }
    }
  }
}
</style>
